import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

import getFeatureFlagPreference from '@braincube/feature-flag/es/services/api/preferencesService';

function useFeatureFlag(queryKey, featureFlagKey, siteUuid, productUuid) {
    const getContextPreference = useCallback(async (context, featureKey, contextUuid, xAppKey) => {
        try {
            return await getFeatureFlagPreference(context, featureKey, contextUuid, xAppKey);
        } catch (error) {
            console.log(
                `Cannot retrieve preferences data on ${context} preferences service. Return false value to skip this context.`,
                {
                    featureKey,
                },
                error
            );
            return false;
        }
    }, []);

    const getFlag = useCallback(async () => {
        return (
            (await getContextPreference('global', featureFlagKey, undefined, import.meta.env.VITE_APP_PACKAGE_NAME)) ||
            (siteUuid
                ? await getContextPreference('sites', featureFlagKey, siteUuid, import.meta.env.VITE_APP_PACKAGE_NAME)
                : false) ||
            (productUuid
                ? await getContextPreference(
                      'products',
                      featureFlagKey,
                      productUuid,
                      import.meta.env.VITE_APP_PACKAGE_NAME
                  )
                : false) ||
            // eslint-disable-next-line no-return-await
            (await getContextPreference('users', featureFlagKey, undefined, import.meta.env.VITE_APP_PACKAGE_NAME))
        );
    }, [featureFlagKey, getContextPreference, productUuid, siteUuid]);

    return useQuery({
        queryKey,
        queryFn: getFlag,
        gcTime: 30 * 60 * 1000,
        refetchInterval: 30 * 60 * 1000,
        enabled: !!siteUuid && !!productUuid,
    });
}

export default useFeatureFlag;
