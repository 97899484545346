import React, { useCallback, useMemo, useState } from 'react';
import { Button, DialogActions, DialogContent, TextField } from '@mui/material';
import { useI18n } from '@braincube/i18n';
import { useApplicationTemplate } from 'components/App/DigitalAssistantCreator/context/digitalAssistantCreatorContext';
import PropTypes from 'prop-types';

function BuildPaste({ onCancel, onConfirm }) {
    const i18n = useI18n();

    const { setBuildFile, buildFile } = useApplicationTemplate();

    const [value, setValue] = useState(buildFile ? JSON.stringify(buildFile) : '');
    const [formatError, setFormatError] = useState(false);

    const handleChange = useCallback(
        (event) => {
            const jsonContent = event.target.value;
            setValue(jsonContent);

            try {
                const json = JSON.parse(jsonContent);
                // allow only objects
                if (typeof json !== 'object' || Array.isArray(json)) {
                    throw new Error();
                }
                setBuildFile(json);
                setFormatError(false);
            } catch (e) {
                setFormatError(true);
            }
        },
        [setBuildFile]
    );

    const errorText = useMemo(() => {
        if (!value) {
            return i18n.tc('yup.required');
        }

        if (formatError) {
            return i18n.tc('digitalAssistantCreator.fields.build.error');
        }

        return '';
    }, [value, formatError, i18n]);

    return (
        <>
            <DialogContent>
                <TextField
                    data-testid="build-paste"
                    // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                    sx={{ mt: 2 }}
                    label={i18n.tc('digitalAssistantCreator.fields.build.label')}
                    required
                    fullWidth
                    multiline
                    rows={10}
                    onChange={handleChange}
                    value={value}
                    error={!!errorText}
                    helperText={errorText}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onCancel} data-testid="build-paste-cancel">
                    {i18n.tc('common.cancel')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onConfirm}
                    disabled={!!errorText}
                    data-testid="build-paste-next"
                >
                    {i18n.tc('dialog.next')}
                </Button>
            </DialogActions>
        </>
    );
}

BuildPaste.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default BuildPaste;
