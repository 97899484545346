import React, { createContext, useContext, useMemo, useState } from 'react';

const DigitalAssistantCreatorContext = createContext({});

export function DigitalAssistantCreatorContextProvider({ children }) {
    const [buildFile, setBuildFile] = useState('');

    const values = useMemo(() => ({ buildFile, setBuildFile }), [buildFile, setBuildFile]);

    return <DigitalAssistantCreatorContext.Provider value={values}>{children}</DigitalAssistantCreatorContext.Provider>;
}

export const useApplicationTemplate = () => useContext(DigitalAssistantCreatorContext);
