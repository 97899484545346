import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import { getLocaleKey, I18nProvider } from '@braincube/i18n';
import { storeProductUuid } from '@braincube/jwt-browser';
import Tracker from '@braincube/tracker';
import { Toolbar, CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';

import React, { useCallback, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import {
    Header,
    HeaderApplicationDescriptor,
    useIsReady,
    HeaderContextProvider,
    HeaderPalette,
    HeaderLogo,
    headerLabels,
    useSites,
    usePreferences,
    useMe,
} from '@braincube/header';
import { AppsManager, AppsManagerFlat } from '@braincube/svg';
import { buildTheme } from '@braincube/theme';
import Logger from '@braincube/logger';
import { i18nLabels, LabelsMapping, SplashScreen } from '@braincube/ui';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AppHeader from 'components/App/AppHeader';
import ProductSelector from './components/ProductSelector';

import store from './redux/store';

import { initHeaderInfo } from './redux/modules/accesses';

import GlobalErrorBoundary from './components/GlobalErrorBoundary';
import App from './components/App/index';
import localesMap from './services/lang';

const queryClient = new QueryClient();

const AppWrapper = connect(null, (dispatch) => ({
    initHeaderInfoFn: (me, selected) => dispatch(initHeaderInfo(me, selected)),
}))(({ initHeaderInfoFn }) => {
    const { setSelectedElement, braincubeProduct, selectedSite } = useSites();
    const me = useMe();

    const changeProductSelector = useCallback(
        ({ me: mySelf, selected }) => {
            storeProductUuid(selected.product.productId);
            setSelectedElement(selected);
            initHeaderInfoFn(mySelf, selected);
        },
        [initHeaderInfoFn, setSelectedElement]
    );
    const isReady = useIsReady();
    const preferences = usePreferences();

    const localeKey = getLocaleKey(preferences.locale);

    const i18nProviderLabels = useMemo(() => {
        return {
            ...localesMap.getLabels(localeKey),
            ...headerLabels.getLabels(localeKey),
            ...LabelsMapping.getLabels(localeKey),
            ...i18nLabels.getLabels(localeKey),
        };
    }, [localeKey]);

    useEffect(() => {
        if (braincubeProduct && selectedSite && me) {
            Tracker.configure({
                appName: 'app-store-front',
                apiName: braincubeProduct.name,
                appVersion: import.meta.env.VITE_APP_VERSION,
                selectedSiteName: selectedSite.siteName,
                user: me.user,
                matomoSiteId: '20',
            });
        }
    }, [braincubeProduct, selectedSite, me]);

    return (
        <I18nProvider locale={localeKey} labels={i18nProviderLabels}>
            <SplashScreen
                AppLogo={AppsManagerFlat}
                appName="Apps Manager"
                appVersion={import.meta.env.VITE_APP_VERSION}
                isContentLoaded={isReady}
            >
                <Header>
                    <HeaderPalette />
                    <HeaderLogo />
                    <ProductSelector onChange={changeProductSelector} />
                    <HeaderApplicationDescriptor
                        logo={AppsManager}
                        version={import.meta.env.VITE_APP_VERSION}
                        title="Apps Manager"
                    />
                    <QueryClientProvider client={queryClient}>
                        <AppHeader />
                    </QueryClientProvider>
                </Header>

                <div>
                    <Toolbar />

                    <App />
                </div>
            </SplashScreen>
        </I18nProvider>
    );
});

const head = document.getElementsByTagName('head')[0];
const script = document.createElement('script');

script.async = false;
script.type = 'text/javascript';
if (import.meta.env.DEV) {
    script.src = `https://cdn.test.mybraincube.com/configuration.js?timestamp=${Date.now()}`;
} else {
    script.src = `https://${window.location.hostname.replace(
        'appsmanager',
        'cdn'
    )}/configuration.js?timestamp=${Date.now()}`;
}

const Init = () => {
    useEffect(() => {
        Logger.configure({
            sentry: import.meta.env.VITE_LOGGER_SENTRY === 'true',
            appName: 'app-store-front',
            version: import.meta.env.VITE_APP_VERSION,
            sentryProjectId: 23,
            sentryToken: 'a7651c098d974210a5934d39495b9d93',
        });
    }, []);

    return (
        <GlobalErrorBoundary>
            <Provider store={store}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={buildTheme()}>
                        <CssBaseline />
                        <HeaderContextProvider>
                            <AppWrapper />
                        </HeaderContextProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </Provider>
        </GlobalErrorBoundary>
    );
};

script.onload = function render() {
    ReactDOM.render(<Init />, document.getElementById('root'));
};

head.appendChild(script);
