import PropTypes from 'prop-types';

const fieldPropType = {
    property: PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        inputType: PropTypes.string.isRequired,
        default: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.number,
            PropTypes.object,
            PropTypes.array,
        ]).isRequired,
    }).isRequired,
};

export default fieldPropType;
