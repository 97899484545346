import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useI18n } from '@braincube/i18n';
import { InputAdornment, Tooltip, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function PasswordDisplayToggle({ showPassword, onPasswordToggle }) {
    const i18n = useI18n();

    const handleClickShowPassword = useCallback(() => {
        onPasswordToggle(!showPassword);
    }, [showPassword, onPasswordToggle]);

    const handleMouseDownPassword = useCallback((event) => {
        event.preventDefault();
    }, []);

    const tooltipTitle = useMemo(
        () =>
            showPassword
                ? i18n.tc('digitalAssistantCreator.fields.adornment.hideInput')
                : i18n.tc('digitalAssistantCreator.fields.adornment.displayInput'),
        [showPassword, i18n]
    );

    return (
        <InputAdornment position="end">
            <Tooltip title={tooltipTitle}>
                <IconButton
                    aria-label={tooltipTitle}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </Tooltip>
        </InputAdornment>
    );
}

PasswordDisplayToggle.propTypes = {
    showPassword: PropTypes.bool.isRequired,
    onPasswordToggle: PropTypes.func.isRequired,
};

export default PasswordDisplayToggle;
