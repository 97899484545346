import { Dialog, DialogTitle } from '@mui/material';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import BuildPaste from 'components/App/DigitalAssistantCreator/components/BuildPaste';
import { useApplicationTemplate } from 'components/App/DigitalAssistantCreator/context/digitalAssistantCreatorContext';
import Stepper from 'components/App/DigitalAssistantCreator/components/Stepper';

function DigitalAssistantCreator({ opened, onClose }) {
    const { setBuildFile } = useApplicationTemplate();
    const [displayStepper, setDisplayStepper] = useState(false);

    const doClose = useCallback(() => {
        onClose();

        // We use a timeout to avoid the stepper to be hidden during the closing animation
        setTimeout(() => {
            setBuildFile('');
            setDisplayStepper(false);
        }, 250);
    }, [onClose, setBuildFile]);

    const onCancel = useCallback(() => {
        doClose();

        // @TODO : Reset form logic here
    }, [doClose]);

    const onConfirmBuildPaste = useCallback(() => {
        setDisplayStepper(true);
    }, []);

    const hideStepper = useCallback(() => {
        setDisplayStepper(false);
    }, []);

    return (
        <Dialog open={opened} onClose={doClose} fullWidth maxWidth={500}>
            <DialogTitle>Digital Assistant Creator</DialogTitle>

            {displayStepper ? (
                <Stepper onPrev={hideStepper} />
            ) : (
                <BuildPaste onConfirm={onConfirmBuildPaste} onCancel={onCancel} />
            )}
        </Dialog>
    );
}

DigitalAssistantCreator.propTypes = {
    opened: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default DigitalAssistantCreator;
