import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const sx = { overflowY: 'auto', display: 'flex' };

function FitFormContent({ children, verticalCentered, thinPaddingTop }) {
    return (
        <Box
            alignSelf="center"
            width={500}
            flex={1}
            pt={thinPaddingTop ? 1 : 4}
            sx={sx}
            flexDirection="column"
            justifyContent={verticalCentered ? 'center' : 'flex-start'}
        >
            {children}
        </Box>
    );
}

FitFormContent.propTypes = {
    children: PropTypes.node.isRequired,
    verticalCentered: PropTypes.bool,
    thinPaddingTop: PropTypes.bool,
};

FitFormContent.defaultProps = {
    verticalCentered: false,
    thinPaddingTop: false,
};

export default FitFormContent;
