import { Button, DialogActions } from '@mui/material';
import React from 'react';
import { useFormikContext } from 'formik';
import { useI18n } from '@braincube/i18n';
import PropTypes from 'prop-types';

function StepperActions({ currentStep, onPrev, onBack, onNext }) {
    const { isValid, handleSubmit } = useFormikContext();
    const i18n = useI18n();

    return (
        <DialogActions>
            {/* Previous button */}
            <Button variant="contained" onClick={currentStep > 0 ? onBack : onPrev} data-testid="stepper-prev">
                {i18n.tc('dialog.previous')}
            </Button>

            {/* Next or publish button */}
            {currentStep === 0 ? (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onNext}
                    data-testid="stepper-next"
                    disabled={!isValid}
                >
                    {i18n.tc('dialog.next')}
                </Button>
            ) : (
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={!isValid}
                    data-testid="stepper-publish"
                >
                    {i18n.tc('common.publish')}
                </Button>
            )}
        </DialogActions>
    );
}

StepperActions.propTypes = {
    currentStep: PropTypes.number.isRequired,
    onPrev: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
};

export default StepperActions;
