import { IconButton, Tooltip } from '@mui/material';
import { Assistant } from '@mui/icons-material';
import { HeaderActions, HeaderMenu, HeaderWidgets, useSites } from '@braincube/header';
import { SnackbarProvider } from 'notistack';
import Menu from 'components/App/Menu';
import React, { useCallback, useState } from 'react';
import { DigitalAssistantCreatorContextProvider } from 'components/App/DigitalAssistantCreator/context/digitalAssistantCreatorContext';
import DigitalAssistantCreator from 'components/App/DigitalAssistantCreator';
import useFeatureFlag from 'services/hooks/useFeatureFlag';

const anchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
};

function AppHeader() {
    const { selectedElement } = useSites();

    const { data: digitalAssistantAvailable } = useFeatureFlag(
        ['digital-assistant-feature-flag', { productId: selectedElement?.product?.productId }],
        'appStore.digitalAssistantAvailable',
        selectedElement?.product?.site?.siteUuid,
        selectedElement?.product?.productId
    );

    const [digitalAssistanceCreatorOpened, setDigitalAssistanceCreatorOpened] = useState(false);

    const openDigitalAssistantCreator = useCallback(() => setDigitalAssistanceCreatorOpened(true), []);

    const closeDigitalAssistanceCreator = useCallback(() => setDigitalAssistanceCreatorOpened(false), []);

    return (
        <SnackbarProvider anchorOrigin={anchorOrigin}>
            <HeaderActions>
                {digitalAssistantAvailable && (
                    <Tooltip title="Digital Assistant Creator" placement="bottom">
                        <IconButton
                            color="inherit"
                            onClick={openDigitalAssistantCreator}
                            data-testid="open-digital-assistant"
                        >
                            <Assistant />
                        </IconButton>
                    </Tooltip>
                )}
                <HeaderWidgets />
                <HeaderMenu>
                    <Menu />
                </HeaderMenu>
            </HeaderActions>

            {digitalAssistantAvailable && (
                <DigitalAssistantCreatorContextProvider>
                    <DigitalAssistantCreator
                        opened={digitalAssistanceCreatorOpened}
                        onClose={closeDigitalAssistanceCreator}
                    />
                </DigitalAssistantCreatorContextProvider>
            )}
        </SnackbarProvider>
    );
}

export default AppHeader;
